import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import Header from "./components/layout/header";
import Home from "./screens/home";

import articles from "./data";

export default function App() {
    console.log(articles);
    return <>
        <Router>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
            </Routes>
        </Router>
    </>;
}