import "./header.css"
import Player from "../../../components/player";
import {Link} from "react-router-dom";
import {FaHouse, FaFacebook, FaInstagram, FaTiktok, FaXTwitter} from "react-icons/fa6"
import Container from "react-bootstrap/Container";

export default function Index() {
    return <header>
        <Container as="nav" fluid={true}>
            <a href="/" className="logo"><img src="/files/img/logo.svg" alt="Fresh radio" /></a>
            <Player />
            <ul className="socials">
                <li><Link to="https://www.facebook.com/FreshradioOva/"><FaFacebook /></Link></li>
                <li><Link to="https://twitter.com/FreshRadioOva"><FaXTwitter /></Link></li>
                <li><Link to="https://instagram.com/fresh_radio_ostrava"><FaInstagram /></Link></li>
                <li><Link to="https://www.tiktok.com/@fresh_radio_ostrava/"><FaTiktok /></Link></li>
            </ul>
            <ul>
                <li><Link to="/"><FaHouse /></Link></li>
                <li><Link to="/novinky">Aktuality</Link></li>
                <li><Link to="/porady">Pořady</Link></li>
                <li><Link to="/team">Team</Link></li>
                <li><Link to="/reklama">Reklama</Link></li>
                <li><Link to="/fresh-souteze">Fresh Soutěže</Link></li>
            </ul>
        </Container>
    </header>;
}