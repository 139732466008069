import React, {useEffect, useRef, useState} from "react";
import {HAS_MEDIA_SESSION} from "./index";

import { BsPlay, BsPause, BsVolumeMute, BsVolumeUp, BsVolumeDown } from 'react-icons/bs';

export default function Controls({audioRef, audioSrc, setLoadEnabled}) {
    const [isPlaying, setIsPlaying] = useState(localStorage.getItem('player-start') === 'true');
    const [isMuted, setIsMuted] = useState(false);
    const [volume, setVolume] = useState(localStorage.getItem('player-volume') || 100);

    const isReady = useRef(false);

    useEffect(() => {
        if (isPlaying) {
            audioRef.current.volume = volume / 100;
            if (isReady.current) {
                audioRef.current.load();
            }

            let promise = audioRef.current.play();

            if (promise !== undefined) {
                promise.then(_ => {
                }).catch(_ => {
                    setIsPlaying(false);
                });
            }

            if (HAS_MEDIA_SESSION) {
                navigator.mediaSession.playbackState = "playing";
            }

            localStorage.setItem('player-start', 'true');
            setLoadEnabled(true);
        } else {
            if (HAS_MEDIA_SESSION) {
                navigator.mediaSession.playbackState = "paused";
            }
            audioRef.current.pause();
            localStorage.setItem('player-start', 'false');
            setLoadEnabled(false);
        }

        return () => {
            isReady.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPlaying, isReady, audioRef]);

    useEffect(() => {
        if (isReady.current) {
            isReady.current = true;
        }

        if (navigator?.mediaSession) {
            navigator.mediaSession.setActionHandler('play', () => setIsPlaying(true));
            navigator.mediaSession.setActionHandler('pause', () => setIsPlaying(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        audioRef.current.volume = Math.max(0, Math.min(1, volume / 100));
        localStorage.setItem('player-volume', volume);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [volume]);

    useEffect(() => {
        audioRef.current.muted = isMuted;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMuted]);

    const handleVolumeChange = (ev) => {
        setIsMuted(false);
        setVolume(Math.round(ev.target.value));
    }

    return (<div className="player-controls">
        <button className="player-controls-state" type="button" onClick={() => setIsPlaying(!isPlaying)}>{isPlaying ? <BsPause /> : <BsPlay />}</button>
        <button className="player-controls-mute" type="button" onClick={() => setIsMuted(!isMuted)}>{isMuted ? <BsVolumeMute /> : volume > 50 ? <BsVolumeUp /> : volume > 0 ? <BsVolumeDown /> : <BsVolumeMute />}</button>
        <input className="player-controls-volume" type="range" min="0" max="100" title="<< Hlasitost >>" defaultValue={volume} onChange={(ev) => handleVolumeChange(ev)} />
    </div>);
}