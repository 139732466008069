import React, {useEffect, useRef, useState} from "react";
import {HAS_MEDIA_SESSION} from "./index";

export default function PlayerInfo({loadEnabled}) {
    const [artist, setArtist] = useState('-');
    const [title, setTitle] = useState('-');
    const [trackText, setTrackText] = useState('-');
    const [artwork, setArtwork] = useState(null);

    const timeoutRef = useRef();

    const loadData = () => {
        if (loadEnabled) {
            fetch('https://api.beatzone.cz/radio/fresh.json', {cache: "no-store"}).then((response) => {
                return response.json();
            }).then((data) => {
                setArtist(data.now_playing?.song?.artist);
                setTitle(data.now_playing?.song?.title);
                setTrackText(data.now_playing?.song?.text);

                if (loadEnabled) {
                    timeoutRef.current = setTimeout(loadData, 15_000);
                }
            });
        } else {

            setArtist(null);
            setTitle(null);
            setTrackText(null);
        }
    }

    useEffect(() => {
        loadData();

        return () => clearTimeout(timeoutRef.current); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        loadData(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadEnabled]);

    useEffect(() => {
        loadData();

        return () => clearTimeout(timeoutRef.current); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const getCover = async () => {
            if (!loadEnabled) {
                setArtwork(null);
                return;
            }
            let query = encodeURIComponent(`artist:${artist} AND recording:${title}`);
            const apiUrl = `https://musicbrainz.org/ws/2/release/?query=${query}&fmt=json`
            setArtwork(null);

            try {
                const response = await fetch(apiUrl);
                const data = await response.json();
                for (let x in data.releases) {
                    let release = data.releases[x];
                    if (release['status-id'] === '4e304316-386d-3409-af2e-78857eec5cfe' && release['score'] > 80) {
                        try {
                            const response = await fetch(`https://coverartarchive.org/release/${release.id}`);
                            const data = await response.json();
                            try {
                                setArtwork(data?.images[0]?.thumbnails.small);
                                return;
                            } catch (err) {
                            }
                        } catch (err) {

                        }
                    }
                }
            } catch (err) {
            }
        }

        if (title && artist) {
            getCover();
        }
    }, [title, artist, loadEnabled]);

    useEffect(() => {
        let timeout = null;
        if (HAS_MEDIA_SESSION) {

            navigator.mediaSession.metadata = null;
            timeout = setTimeout(() => {
                navigator.mediaSession.metadata = new MediaMetadata({
                    artist: artist || 'Fresh Radio',
                    title: title || '103.6 FM',
                    album: '',
                    artwork: [{src: artwork || '/files/img/logo.svg'}]
                });
            }, 1_000);
        }

        return () => {
            if (HAS_MEDIA_SESSION) {
                navigator.mediaSession.metadata = null;
                clearTimeout(timeout);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, artist, trackText, artwork]);

    return (<div className="player-info">
        <img className="player-info-artwork" src={artwork || '/files/img/logo.svg'} alt={trackText} width={42} height={42} />
        <div className="player-info-wrapper">
            {title && <div className="player-info-title">{title}</div>}
            {artist && <div className="player-info-artist">{artist}</div>}
        </div>
    </div>);
}