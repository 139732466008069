const articles = [{
    "Title": "FASHION BY KATE – PODZIMNÍ KOLEKCE",
    "Slug": "fashion-by-kate-podzimni-kolekce",
    "Perex": "Dámy a slečny!Podzim je tady a s ním klasická otázka – co na sebe? Naše nová moderátorská kolegyně Kateřina, která bude chystat víkendový Skandál!, má pro vás odpověď.Nakombinovala 10 modelů z kolekce Fashion by […]",
    "Content": "<p class=\"has-text-align-center\">Dámy a slečny!<br>Podzim je tady a s ním klasická otázka – co na sebe? Naše nová moderátorská kolegyně Kateřina, která bude chystat víkendový Skandál!, má pro vás odpověď.<br>Nakombinovala 10 modelů z kolekce Fashion by Kate, což je její obchůdek se stylovou a přitom cenově velice dostupnou módou, který najdete v centru na Tyršovce. Všechny jsou chic a hlavně, všechny jsou za cenu kolem tisícovky! Koukněte.<br>A pokud chcete získat tuhle tisícovku na nákup právě do Fashion by Kate, jděte na náš <a href=\"https://www.facebook.com/FreshradioOva/posts/pfbid0uWzbHyWcSEpDSstFicmmYtic4nevaXBF3HDbGphEbrEDB5PeQqCLngAXzZHupiFLl\" data-type=\"link\" data-id=\"https://www.facebook.com/FreshradioOva/posts/pfbid0uWzbHyWcSEpDSstFicmmYtic4nevaXBF3HDbGphEbrEDB5PeQqCLngAXzZHupiFLl\">Facebook</a> a tak dejte Like modelu, který se právě vám nejvíce líbí a nasdílejte tuhle soutěž přátelům. Ti, kteří tak učiní (Like&amp;Share), budou ve slosování. Vítěze vybereme v pátek 20. října.<br>A hlavně – zajděte si do Fashion by Kate vybrat, podzimní kolekce právě dorazila.</p>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<figure class=\"wp-block-gallery has-nested-images columns-default is-cropped wp-block-gallery-1 is-layout-flex wp-block-gallery-is-layout-flex\">\n" +
        "<figure class=\"wp-block-image size-large\"><img decoding=\"async\" width=\"683\" height=\"1024\" data-id=\"826\" src=\"https://www.freshradio.cz/wp-content/uploads/2023/10/306a-1-683x1024.jpg\" alt=\"\" class=\"wp-image-826\" srcset=\"https://www.freshradio.cz/wp-content/uploads/2023/10/306a-1-683x1024.jpg 683w, https://www.freshradio.cz/wp-content/uploads/2023/10/306a-1-768x1152.jpg 768w, https://www.freshradio.cz/wp-content/uploads/2023/10/306a-1.jpg 1000w\" sizes=\"(max-width: 683px) 100vw, 683px\"></figure>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<figure class=\"wp-block-image size-large\"><img decoding=\"async\" width=\"683\" height=\"1024\" data-id=\"830\" src=\"https://www.freshradio.cz/wp-content/uploads/2023/10/403a-1-683x1024.jpg\" alt=\"\" class=\"wp-image-830\" srcset=\"https://www.freshradio.cz/wp-content/uploads/2023/10/403a-1-683x1024.jpg 683w, https://www.freshradio.cz/wp-content/uploads/2023/10/403a-1-768x1152.jpg 768w, https://www.freshradio.cz/wp-content/uploads/2023/10/403a-1.jpg 1000w\" sizes=\"(max-width: 683px) 100vw, 683px\"></figure>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<figure class=\"wp-block-image size-large\"><img decoding=\"async\" width=\"683\" height=\"1024\" data-id=\"824\" src=\"https://www.freshradio.cz/wp-content/uploads/2023/10/340a-1-683x1024.jpg\" alt=\"\" class=\"wp-image-824\" srcset=\"https://www.freshradio.cz/wp-content/uploads/2023/10/340a-1-683x1024.jpg 683w, https://www.freshradio.cz/wp-content/uploads/2023/10/340a-1-768x1152.jpg 768w, https://www.freshradio.cz/wp-content/uploads/2023/10/340a-1.jpg 1000w\" sizes=\"(max-width: 683px) 100vw, 683px\"></figure>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<figure class=\"wp-block-image size-large\"><img decoding=\"async\" loading=\"lazy\" width=\"683\" height=\"1024\" data-id=\"825\" src=\"https://www.freshradio.cz/wp-content/uploads/2023/10/426a-1-683x1024.jpg\" alt=\"\" class=\"wp-image-825\" srcset=\"https://www.freshradio.cz/wp-content/uploads/2023/10/426a-1-683x1024.jpg 683w, https://www.freshradio.cz/wp-content/uploads/2023/10/426a-1-768x1152.jpg 768w, https://www.freshradio.cz/wp-content/uploads/2023/10/426a-1.jpg 1000w\" sizes=\"(max-width: 683px) 100vw, 683px\"></figure>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<figure class=\"wp-block-image size-large\"><img decoding=\"async\" loading=\"lazy\" width=\"683\" height=\"1024\" data-id=\"833\" src=\"https://www.freshradio.cz/wp-content/uploads/2023/10/434a-1-683x1024.jpg\" alt=\"\" class=\"wp-image-833\" srcset=\"https://www.freshradio.cz/wp-content/uploads/2023/10/434a-1-683x1024.jpg 683w, https://www.freshradio.cz/wp-content/uploads/2023/10/434a-1-768x1152.jpg 768w, https://www.freshradio.cz/wp-content/uploads/2023/10/434a-1.jpg 1000w\" sizes=\"(max-width: 683px) 100vw, 683px\"></figure>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<figure class=\"wp-block-image size-large\"><img decoding=\"async\" loading=\"lazy\" width=\"683\" height=\"1024\" data-id=\"828\" src=\"https://www.freshradio.cz/wp-content/uploads/2023/10/507a-1-683x1024.jpg\" alt=\"\" class=\"wp-image-828\" srcset=\"https://www.freshradio.cz/wp-content/uploads/2023/10/507a-1-683x1024.jpg 683w, https://www.freshradio.cz/wp-content/uploads/2023/10/507a-1-768x1152.jpg 768w, https://www.freshradio.cz/wp-content/uploads/2023/10/507a-1.jpg 1000w\" sizes=\"(max-width: 683px) 100vw, 683px\"></figure>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<figure class=\"wp-block-image size-large\"><img decoding=\"async\" loading=\"lazy\" width=\"683\" height=\"1024\" data-id=\"831\" src=\"https://www.freshradio.cz/wp-content/uploads/2023/10/547a-1-683x1024.jpg\" alt=\"\" class=\"wp-image-831\" srcset=\"https://www.freshradio.cz/wp-content/uploads/2023/10/547a-1-683x1024.jpg 683w, https://www.freshradio.cz/wp-content/uploads/2023/10/547a-1-768x1152.jpg 768w, https://www.freshradio.cz/wp-content/uploads/2023/10/547a-1.jpg 1000w\" sizes=\"(max-width: 683px) 100vw, 683px\"></figure>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<figure class=\"wp-block-image size-large\"><img decoding=\"async\" loading=\"lazy\" width=\"683\" height=\"1024\" data-id=\"832\" src=\"https://www.freshradio.cz/wp-content/uploads/2023/10/587a-1-683x1024.jpg\" alt=\"\" class=\"wp-image-832\" srcset=\"https://www.freshradio.cz/wp-content/uploads/2023/10/587a-1-683x1024.jpg 683w, https://www.freshradio.cz/wp-content/uploads/2023/10/587a-1-768x1152.jpg 768w, https://www.freshradio.cz/wp-content/uploads/2023/10/587a-1.jpg 1000w\" sizes=\"(max-width: 683px) 100vw, 683px\"></figure>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<figure class=\"wp-block-image size-large\"><img decoding=\"async\" loading=\"lazy\" width=\"683\" height=\"1024\" data-id=\"829\" src=\"https://www.freshradio.cz/wp-content/uploads/2023/10/693a-1-683x1024.jpg\" alt=\"\" class=\"wp-image-829\" srcset=\"https://www.freshradio.cz/wp-content/uploads/2023/10/693a-1-683x1024.jpg 683w, https://www.freshradio.cz/wp-content/uploads/2023/10/693a-1-768x1152.jpg 768w, https://www.freshradio.cz/wp-content/uploads/2023/10/693a-1-1024x1536.jpg 1024w, https://www.freshradio.cz/wp-content/uploads/2023/10/693a-1.jpg 1028w\" sizes=\"(max-width: 683px) 100vw, 683px\"></figure>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<figure class=\"wp-block-image size-large\"><img decoding=\"async\" loading=\"lazy\" width=\"683\" height=\"1024\" data-id=\"838\" src=\"https://www.freshradio.cz/wp-content/uploads/2023/10/380a-683x1024.jpg\" alt=\"\" class=\"wp-image-838\" srcset=\"https://www.freshradio.cz/wp-content/uploads/2023/10/380a-683x1024.jpg 683w, https://www.freshradio.cz/wp-content/uploads/2023/10/380a-768x1152.jpg 768w, https://www.freshradio.cz/wp-content/uploads/2023/10/380a.jpg 1000w\" sizes=\"(max-width: 683px) 100vw, 683px\"></figure>\n" +
        "</figure>"
}, {
    "Title": "TŘI TYGŘI NA FRESH RÁDIO",
    "Slug": "tri-tygri-na-fresh-radiu",
    "Perex": "Humor léčí. A fórky v podání TŘÍ TYGRŮ – to je šoková terapie. Dopřejte si jich dosytosti dvakrát týdně mezi 8-9.00 nebo v repríze v sobotu a neděli dopoledne. Dejte Like nebo Follow www.facebook.com/FreshradioOva/ a neuniknou vám ani videa z vysílání.",
    "Content": "<p>Humor léčí. A fórky v podání TŘÍ TYGRŮ – to je šoková terapie. Dopřejte si jich dosytosti dvakrát týdně mezi 8-9.00 nebo v repríze v sobotu a neděli dopoledne. Dejte Like nebo Follow <a href=\"http://www.facebook.com/FreshradioOva/\" data-type=\"link\" data-id=\"www.facebook.com/FreshradioOva/\">www.facebook.com/FreshradioOva/</a> a neuniknou vám ani videa z vysílání.</p>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<figure class=\"wp-block-image size-large\"><img decoding=\"async\" width=\"1024\" height=\"576\" src=\"https://www.freshradio.cz/wp-content/uploads/2023/09/TT-exkluzivne-1024x576.png\" alt=\"\" class=\"wp-image-809\" srcset=\"https://www.freshradio.cz/wp-content/uploads/2023/09/TT-exkluzivne-1024x576.png 1024w, https://www.freshradio.cz/wp-content/uploads/2023/09/TT-exkluzivne-768x432.png 768w, https://www.freshradio.cz/wp-content/uploads/2023/09/TT-exkluzivne-1536x865.png 1536w, https://www.freshradio.cz/wp-content/uploads/2023/09/TT-exkluzivne.png 2029w\" sizes=\"(max-width: 1024px) 100vw, 1024px\"></figure>"
}, {
    "Title": "TAJNÝ KÓD",
    "Slug": "tajny-kod",
    "Perex": "Dal by sis něco dobrého? Pizzu, wrap nebo špecle? Easy. Stáhni si přes QR appku Cartel Pizza! A jestli v tom jedeš s náma, máš šanci na dárek od Fresh. Nalaď v pátek odpoledne Refresh s Markem a dávej bacha. Bude dodávka. Cartel dodá Markovi něco na zub a on to vymění za dárky pro vás. Pak stačí jen co nejrychleji objednat a při dodávce říct řidiči TAJNÝ KÓD. Pokud budeš dost rychlý, dostaneš k papání ještě FRESH DÁREK!",
    "Content": "<p>Dal by sis něco dobrého? Pizzu, wrap nebo špecle? Easy. Stáhni si přes QR appku <a href=\"https://www.cartelpizza.cz/ostrava#home\" data-type=\"URL\" data-id=\"https://www.cartelpizza.cz/ostrava#home\"><strong>Cartel Pizza</strong></a>! A jestli v tom jedeš s náma, máš šanci na dárek od Fresh. Nalaď v pátek odpoledne Refresh s Markem a dávej bacha. Bude dodávka. Cartel dodá Markovi něco na zub a on to vymění za dárky pro vás. Pak stačí jen co nejrychleji objednat a při dodávce říct řidiči TAJNÝ KÓD. Pokud budeš dost rychlý, dostaneš k papání ještě FRESH DÁREK!</p>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<figure class=\"wp-block-image size-large\"><img decoding=\"async\" width=\"1024\" height=\"717\" src=\"https://www.freshradio.cz/wp-content/uploads/2023/08/tk-1024x717.jpg\" alt=\"\" class=\"wp-image-670\" srcset=\"https://www.freshradio.cz/wp-content/uploads/2023/08/tk-1024x717.jpg 1024w, https://www.freshradio.cz/wp-content/uploads/2023/08/tk-768x537.jpg 768w, https://www.freshradio.cz/wp-content/uploads/2023/08/tk-1536x1075.jpg 1536w, https://www.freshradio.cz/wp-content/uploads/2023/08/tk-2048x1433.jpg 2048w\" sizes=\"(max-width: 1024px) 100vw, 1024px\"></figure>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<p>Cartel Pizza není jen v Ostravě. Najdete nás v Brně, Olomouci, Přerově, Kuřimi, Frýdku-Místku, Paskově a Havířově.</p>"
}, {
    "Title": "MAŠINERIE – NOVÝ POŘAD O AUTECH A MOTORKÁCH",
    "Slug": "masinerie",
    "Perex": "První neděli v září v 18.00 si odbude svou premiéru nový pořad – Mašinerie. A taky bude mít svůj moderátorský křest Martin Chleboun – člověk, který místo kolínské používá benzín 🙂 Takže pokud máte rádi káry a mašiny, je Mašinerie váš šálek paliva.",
    "Content": "<p>První neděli v září v 18.00 si odbude svou premiéru nový pořad – Mašinerie. A taky bude mít svůj moderátorský křest Martin Chleboun – člověk, který místo kolínské používá benzín 🙂 Takže pokud máte rádi káry a mašiny, je Mašinerie váš šálek paliva.</p>\n" +
        "\n" +
        "<figure class=\"wp-block-image size-large\"><img decoding=\"async\" width=\"1024\" height=\"606\" src=\"https://www.freshradio.cz/wp-content/uploads/2023/08/MASINERIE-1024x606.jpg\" alt=\"\" class=\"wp-image-701\" srcset=\"https://www.freshradio.cz/wp-content/uploads/2023/08/MASINERIE-1024x606.jpg 1024w, https://www.freshradio.cz/wp-content/uploads/2023/08/MASINERIE-768x455.jpg 768w, https://www.freshradio.cz/wp-content/uploads/2023/08/MASINERIE.jpg 1488w\" sizes=\"(max-width: 1024px) 100vw, 1024px\"></figure>"
}, {
    "Title": "Appka k poslechu rádia Fresh",
    "Slug": "appka-k-poslechu-radia-fresh-2",
    "Perex": "Appka je k dispozici, stahuj 👍",
    "Content": "\t\t\n" +
        "<p class=\"has-black-color has-white-background-color has-text-color has-background\"><strong>Chceš poslouchat rádio Fresh přes internet? Není problém.</strong> Nabízíme appku 📢💡</p>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<p></p>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<p><strong>Google Play</strong></p>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<p><a rel=\"noreferrer noopener\" href=\"https://play.google.com/store/apps/details?id=com.soundcontrol.freshradio\" target=\"_blank\">https://play.google.com/store/apps/details?id=com.soundcontrol.freshradio</a></p>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<p></p>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<p><strong>Huawei </strong></p>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<p><a rel=\"noreferrer noopener\" href=\"https://appgallery.huawei.com/app/C107523477\" target=\"_blank\">https://appgallery.huawei.com/app/C107523477</a></p>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<p></p>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<p><strong>Applestore</strong></p>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<p><a href=\"https://apps.apple.com/us/app/fresh-radio-103-6fm/id1664207673\">https://apps.apple.com/us/app/fresh-radio-103-6fm/id1664207673</a></p>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<p></p>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<p class=\"has-vivid-cyan-blue-color has-text-color\" id=\"https://play.google.com/store/apps/details?id=com.crystalmissions.czradio&amp;hl=cs&amp;gl=US\"><strong>Najdete nás například i v aplikacích <a rel=\"noreferrer noopener\" href=\"https://mytuner-radio.com/radio/fresh-radio-czech-republic-494017/\" target=\"_blank\">My Tuner</a></strong>,<strong> <a rel=\"noreferrer noopener\" href=\"https://play.google.com/store/apps/details?id=com.radiofmapp.czradio&amp;pli=1\" target=\"_blank\">RadioFMapp</a></strong>, <a href=\"https://play.google.com/store/apps/details?id=com.crystalmissions.czradio&amp;hl=cs&amp;gl=US\"><strong>CZ Rádio</strong></a>, </p>"
}, {
    "Title": "Rádio FRESH vysílá na 103.6 FM",
    "Slug": "radio-fresh-vysila-na-103-6-fm",
    "Perex": "Rádio FRESH vysílá na 103.6 FM.<br>Co dál? V současné době probíhá zkušební vysílání, ve kterém ladíme softwarová i hardwarová zařízení, dovybavujeme naše studio, které sídlí v OC Forum Nová Karolina a  připravujeme jednotlivé pořady našeho vysílání. Předpokládáme, že do konce ledna by vše mělo být hotovo.",
    "Content": "<p>Rádio Fresh vysílá, to je skvělá zpráva. Začali jsme 24.12.2022 ve 20 hodin. Jsme rádio metropolitní, pro Ostravu, s aktuálním playlistem elektronické hudby. Jsme rádi, že Vás můžeme pobavit, informovat Vás nebo jen tak být Vaším společníkem v práci, autě či doma 🙂</p>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<p>Co dál? V současné době probíhá zkušební vysílání, ve kterém ladíme softwarová i hardwarová zařízení, dovybavujeme naše studio, které sídlí v <a rel=\"noreferrer noopener\" href=\"https://forumnovakarolina.cz\" target=\"_blank\">OC Forum Nová Karolina</a> a  připravujeme jednotlivé pořady našeho vysílání. Předpokládáme, že do konce ledna by vše mělo být hotovo.</p>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<figure class=\"wp-block-gallery has-nested-images columns-default is-cropped wp-block-gallery-1 is-layout-flex wp-block-gallery-is-layout-flex\">\n" +
        "<figure class=\"wp-block-image size-large\"><img decoding=\"async\" width=\"590\" height=\"420\" data-id=\"178\" src=\"https://www.freshradio.cz/wp-content/uploads/2022/12/ales_bruna_00001.jpg\" alt=\"Aleš Brůna\" class=\"wp-image-178\"></figure>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<figure class=\"wp-block-image size-large\"><img decoding=\"async\" width=\"590\" height=\"420\" data-id=\"179\" src=\"https://www.freshradio.cz/wp-content/uploads/2022/12/javor_00000.jpg\" alt=\"Jirka Javorský\" class=\"wp-image-179\"></figure>\n" +
        "</figure>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<p>A co pořady a moderátoři? Jako první odstartovaly pořady, odpolední <strong><a rel=\"noreferrer noopener\" href=\"https://www.freshradio.cz/porad/fresh-mix/\" target=\"_blank\">Fresh Mix s Alešem Brůnou</a> </strong>a dopolední <a rel=\"noreferrer noopener\" href=\"https://www.freshradio.cz/porad/fresh-prince/\" target=\"_blank\"><strong>Fresh Prince s Jirkou Javorským</strong></a>. V pondělí 9.1. startuje ranní vysílání s <a rel=\"noreferrer noopener\" href=\"https://www.freshradio.cz/porad/porad/\" target=\"_blank\"><strong>Marsy, Míšou a Ondrou, tedy Fresh Start</strong></a>, které pravidelně každý pátek vystřídá <strong><a rel=\"noreferrer noopener\" href=\"https://www.freshradio.cz/porad/patecni-fresh-start/\" target=\"_blank\">Páteční Fresh Startem</a> s Luďkem a Vindy</strong>.</p>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<figure class=\"wp-block-gallery has-nested-images columns-default is-cropped wp-block-gallery-3 is-layout-flex wp-block-gallery-is-layout-flex\">\n" +
        "<figure class=\"wp-block-image size-large\"><img decoding=\"async\" width=\"590\" height=\"420\" data-id=\"215\" src=\"https://www.freshradio.cz/wp-content/uploads/2022/12/ondra_marssy_misa_00003.jpg\" alt=\"Fresh Start\" class=\"wp-image-215\"></figure>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<figure class=\"wp-block-image size-large\"><img decoding=\"async\" loading=\"lazy\" width=\"590\" height=\"420\" data-id=\"218\" src=\"https://www.freshradio.cz/wp-content/uploads/2022/12/vindy_ludek_00001.jpg\" alt=\"LUDĚK A VINDY\" class=\"wp-image-218\"></figure>\n" +
        "</figure>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<p>A co očekávané večerní speciály? Těšte se na ně během ledna, čekáme už jen na dovybavení DJského pultu a jdeme na to. <strong>Energia, One Zoo, Urban Extreme, Fresh Culture, Fatal Terror Factory, House Factory, Brick Room, EDM Mania, Friday Mix, Sunday Vibe, Radar a nesmrtelný sobotní pořad Fresh Dance Party</strong>. Více na našich stránkách v sekci <strong><a rel=\"noreferrer noopener\" href=\"https://www.freshradio.cz/porady/\" target=\"_blank\">pořady</a></strong>.</p>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<p>Těšíme se na Vaše ohlasy, feedback, recenze, pochvaly i kritiku. Pište nám na <strong><a rel=\"noreferrer noopener\" href=\"https://www.facebook.com/FreshradioOva/\" target=\"_blank\">Facebook</a>, <a rel=\"noreferrer noopener\" href=\"https://www.instagram.com/fresh_radio_ostrava/\" target=\"_blank\">Instagram</a> </strong>nebo na email<strong> <a href=\"mailto:freshradio@freshradio.cz\" target=\"_blank\" rel=\"noreferrer noopener\">freshradio@freshradio.cz</a></strong>  </p>\n" +
        "\n" +
        "\n" +
        "\n" +
        "<p></p>"
}];

export default articles;