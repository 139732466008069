import React, {useRef, useState} from "react";
import "./player.css";
import Info from "./info";
import Controls from "./controls";

export default function Player() {
    const [loadEnabled, setLoadEnabled] = useState(false);
    const audioSrc = 'https://icecast.beatzone.cz/radio/8000/320.mp3';
    const audioRef = useRef(new Audio(audioSrc));

    return (<div className="player">
        <Controls audioRef={audioRef} audioSrc={audioSrc} setLoadEnabled={setLoadEnabled} />
        <Info loadEnabled={loadEnabled}/>
    </div>);
}

export const HAS_MEDIA_SESSION = window && window.navigator && 'mediaSession' in window.navigator;